import * as React from "react";
import AutoCompleteSearch from "styleguide/components/AutoCompleteSearch/index";
import Service from "bundles/App/services";
import styles from "./index.scss";

const PLACEHOLDER = "City, state, or hotel";
const urlShowAll = `${location.protocol}//${location.host}/browse-hotels`;

class SearchBarContainer extends React.Component {
  onPlaceSelect = (selectedPlace, index) => {
    this.openPlacePage(selectedPlace, index);
  };

  async openPlacePage(selectedPlace, index) {
    let url = selectedPlace && selectedPlace.url;
    if (!url) return;

    const createRedirector = function (url, fn) {
      return function () {
        if (fn) fn();
        window.location.href = url;
      };
    };

    const pushSearchQueryToDataLayer = function () {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "searchQueryPerformed",
        searchQueryValue: selectedPlace.name,
        searchQueryDate: window.sessionStorage.getItem("bookingDate") || "null",
      });
    };

    // regular redirect
    let redirect = createRedirector(url, pushSearchQueryToDataLayer);

    // add query string for algolia tracking
    if (selectedPlace.indexName && selectedPlace.objectID && selectedPlace.queryID) {
      const params = [
        `alg_index_name=${encodeURIComponent(selectedPlace.indexName)}`,
        `alg_obj_id=${encodeURIComponent(selectedPlace.objectID)}`,
        `alg_query_id=${encodeURIComponent(selectedPlace.queryID)}`,
        `alg_pos=${index + 1}`,
      ];
      if (url.indexOf("?") >= 0) {
        url += `&${params.join("&")}`;
      } else {
        url += `?${params.join("&")}`;
      }
      redirect = createRedirector(url, pushSearchQueryToDataLayer);

      // algolia tracking
      var algoliaTrackingData = {
        index_name: selectedPlace.indexName,
        event_type: "view",
        query_id: selectedPlace.queryID,
        object_id: selectedPlace.objectID,
        position: "" + (index + 1),
      };

      try {
        await Service.algoliaTrackAutocomplete(algoliaTrackingData);
        redirect();
      } catch (_) {
        redirect();
      }
    } else {
      redirect();
    }
  }

  render() {
    const { searchType, currentUrl } = this.props;
    const wrapperStyle = styles.wrapper;

    return (
      <>
        <div className={`${wrapperStyle} ${styles[searchType] || ""}`}>
          <AutoCompleteSearch
            onSelect={this.onPlaceSelect}
            placeholder={PLACEHOLDER}
            urlShowAll={urlShowAll}
            textShowAll="See all hotels"
            iconType="icon-search-"
            searchType={searchType}
            currentUrl={currentUrl}
          />
        </div>
      </>
    );
  }
}

export default SearchBarContainer;
