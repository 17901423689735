import React from "react";
import Autocomplete from "react-autocomplete";
import _ from "lodash";
import PropTypes from "prop-types";
import styles from "./index.scss";
import SVGIcon from "../SVGIcon/index";
import Service from "../../../bundles/App/services";
import Spinner from "../Spinner/index";

const { string, func, arrayOf, shape, any } = PropTypes;

const enterKeyCode = 13;
const backspaceKeyCode = 8;

const mainIconSize = {
  width: "24",
  height: "24",
};

const mainIconSizeSmall = {
  width: "15",
  height: "15",
};

const itemIconSize = {
  height: "20",
  width: "20",
};

const itemIconSizeSmall = {
  height: "14",
  width: "14",
};

const crossIconSize = {
  width: "20",
  height: "20",
};

class AutoCompleteSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredList: [],
      value: "",
      selected: null,
      isFocused: false,
      isClearButtonVisible: true,
      isFetchingResults: false,
    };
    this.mainIconSize = null;
    this.itemIconSize = null;
    this.list = [];
    this.selectedViaUrl = "";
    this.isAppleDevice = window.navigator.userAgent.match(/(iPod|iPhone|iPad)/) !== null;
  }

  componentDidMount() {
    document.addEventListener("mousedown", (e) => this.handleClickOutside(e));

    if (this.isAppleDevice) {
      document.addEventListener("touchstart", (e) => this.handleClickOutside(e));
    }

    // check to see if previously search selected item is stored in session storage. Storing the previously selected
    // location enables "back" to restore the previous state.
    var stored = window.sessionStorage.getItem("search-selected-item");
    try {
      var selectedItem = JSON.parse(stored);
      this.setState({
        value: selectedItem.name,
        selected: selectedItem,
      });
    } catch (e) {}
    this.initializeDatePicker();
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", (e) => this.handleClickOutside(e));

    if (this.isAppleDevice) {
      document.removeEventListener("touchstart", (e) => this.handleClickOutside(e));
    }
  }

  initializeDatePicker() {
    // initialize bootstrap datepicker
    var datePicker = window.$(`.${styles["search-datepicker"]}`);
    datePicker.datepicker({
      autoclose: true,
      format: "mm/dd/yyyy",
      startDate: "0d",
      endDate: "+6m",
    });

    // check if existing date in sessionstorage
    var existingDate = window.sessionStorage.getItem("bookingDate");
    if (existingDate) {
      var d = moment(existingDate, "MM/DD/YYYY");
      // date is in the future
      if (d.diff(moment(), "days") >= 0) {
        datePicker.datepicker("setDate", existingDate);
      }
    }

    datePicker.on("changeDate", function (d) {
      if (d.date) {
        window.sessionStorage.setItem("bookingDate", moment(d.date).format("L"));
      } else {
        window.sessionStorage.removeItem("bookingDate");
      }
    });
  }

  getOptions = () => {
    return this.state.filteredList;
  };

  onChange = (e, value) => {
    this.setState({
      value,
      selected: null,
    });
    if (value) {
      this.setState({ isFetchingResults: true });
      this.getFilteredList(value);
    } else {
      this.setState({
        isOpen: value ? true : false,
        isFetchingResults: false,
      });
    }
    this.setClearButtonVisibility(value);
  };

  getFilteredList = _.debounce(async (name) => {
    const filteredList = (await Service.getPlacesByName(name.replaceAll('"', ""))) || [];
    this.setState({
      isFetchingResults: false,
      filteredList,
      isOpen: filteredList?.length || name ? true : false,
    });
  }, 50);

  setClearButtonVisibility(value) {
    if (value) {
      this.setState({ isClearButtonVisible: true });
    } else {
      this.setState({ isClearButtonVisible: false });
    }
  }

  onKeyUp = (e) => {
    switch (e.keyCode) {
      case enterKeyCode: // on Enter Key Up
        this.sendAutoCompleteResult();
        break;
      case backspaceKeyCode: // on Backspace key Up
        this.sendSearchTerm();
        break;
    }
  };

  sendSearchTerm = () => {
    // send searchTerm to GTM which doesn't exist in our data base
    // send SearchTerm to GTM if:
    //    - value is not empty
    //    - no results were found
    const { filteredList, value } = this.state;
    if (!value) return;
    if (!filteredList.length) {
      window.dataLayer.push({
        event: "searchQueryPerformed",
        searchQueryValue: value,
        searchQueryDate: window.sessionStorage.getItem("bookingDate") || "null",
      });
    }
  };

  sendAutoCompleteResult = () => {
    const { selected, value, filteredList } = this.state;

    // We do not send autoComplete if
    //    - a selected item exists (we can assume it has been sent to parent component)`
    //    - no value is entered
    if (selected || !value) return;

    if (filteredList.length) {
      // Take first place item with matching substring
      const firstItem = filteredList[0];
      this.setState({
        selected: firstItem,
        value: firstItem.name,
      });
      this.onSelectWithIndex(firstItem);
    }
  };

  onSelect = (selectedValue, selectedItem) => {
    this.onMenuSelection(selectedValue, selectedItem);
    this.onSelectWithIndex(selectedItem);
  };

  onSelectWithIndex = (selectedItem) => {
    let index = this.state.filteredList.indexOf(selectedItem);
    index = index >= 0 ? index : 1;
    this.props.onSelect(selectedItem, index);
  };

  onMenuSelection = (selectedValue, selectedItem) => {
    this.setState({
      isFocused: false,
      isOpen: false,
      value: selectedValue,
      selected: selectedItem,
    });
    this.saveSearchSelectedItemToStorage(selectedItem);
  };

  saveSearchSelectedItemToStorage = (selectedItem) => {
    window.sessionStorage.setItem("search-selected-item", JSON.stringify(selectedItem));
  };

  onSearch = () => {
    if (this.state.selected) {
      this.onSelectWithIndex(this.state.selected);
    } else if (this.state.filteredList.length) {
      // Take the first element from the list
      const firstElement = this.state.filteredList[0];
      this.onSelectWithIndex(firstElement);
      this.saveSearchSelectedItemToStorage(firstElement);
    } else {
      const { urlShowAll } = this.props;
      if (urlShowAll) {
        let url = urlShowAll;
        // If there is query redirect user to no results
        if (this.state.value) {
          url = urlShowAll + "?no_results=true";
          window.sessionStorage.setItem("search-no-result-query", this.state.value);
        }
        window.location = url;
      }
    }
  };

  handleClickOutside = (event) => {
    const isOutside = this._searchWrap && !this._searchWrap.contains(event.target);

    if (isOutside) {
      this.setState({ isFocused: false, isOpen: false });

      if (this.selectedViaUrl) {
        this.onChange(null, this.selectedViaUrl);
      }
      this._textInput.blur();
    } else {
      this.setState({ isFocused: true });
    }
  };

  setWrapperRef = (node) => {
    this._searchWrap = node;
  };

  renderIcon(item) {
    const { iconType } = this.props;

    if (iconType) {
      return (
        <span className={styles["item-icon"]}>
          <SVGIcon
            name={`${item.type}`}
            fill="#00bac6"
            width={this.itemIconSize.width}
            height={this.itemIconSize.height}
          />
        </span>
      );
    }
  }

  renderShowAllBlock() {
    const { urlShowAll, textShowAll } = this.props;

    if (urlShowAll) {
      return (
        <a href={urlShowAll} className={styles["all-link"]} data-cy="see_all_link">
          {textShowAll}
        </a>
      );
    }
  }

  renderItems = (item) => {
    let query = this.state.value;
    const text = item.name;
    let result = text;

    if (query) {
      // Escape sequence. Special symbols "(" ")", "[", "*", "\", "+", "?"
      const clearQuery = query.replace(/[\\[.+*?(){|^$]/g, "\\$&");
      const regExp = new RegExp(clearQuery, "gi");
      result = result.replace(regExp, function (str) {
        return `<span style="font-family: ProximaNova-bold; color: #000;">${str}</span>`;
      });
    }

    const createMarkup = () => {
      return { __html: result };
    };

    return (
      <div key={`${item.id}_${item.name}`} className={`${styles["menu-item"]} search-select-item`}>
        {this.renderIcon(item)}

        {query ? (
          <span className={`${styles.text}`} dangerouslySetInnerHTML={createMarkup()}></span>
        ) : (
          <span className={`${styles.text}`}>{item.name}</span>
        )}
      </div>
    );
  };

  setIconSize(searchType) {
    if (this.mainIconSize) return;

    if (searchType) {
      this.mainIconSize = mainIconSizeSmall;
      this.itemIconSize = itemIconSizeSmall;
    } else {
      this.mainIconSize = mainIconSize;
      this.itemIconSize = itemIconSize;
    }
  }

  onClear = () => {
    this.onChange(null, "");
  };

  renderOriginal() {
    const { placeholder, searchType } = this.props;
    const { isFocused, isOpen, isClearButtonVisible } = this.state;
    const wrapperClasses = `${styles["search-wrap"]} ${styles[searchType + "-search"] || ""} ${
      isFocused ? styles["is-focused"] : ""
    }`;

    this.setIconSize(searchType);

    return (
      <div className={wrapperClasses} ref={this.setWrapperRef}>
        <span className={styles["main-icon"]}>
          <SVGIcon name="glass" width={this.mainIconSize.width} height={this.mainIconSize.height} fill="#00bac6" />
        </span>
        <Autocomplete
          ref={(component) => {
            this._textInput = component;
          }}
          getItemValue={(item) => item.name}
          value={this.state.value}
          renderInput={(props) => {
            if (searchType) {
              return (
                <label>
                  <input {...props} />
                  <span
                    className={`${styles["clear-icon"]} ${isClearButtonVisible ? styles["is-visible"] : ""}`}
                    onClick={this.onClear}
                  >
                    <SVGIcon name="cross" width={crossIconSize.width} height={crossIconSize.height} fill="#cbcccc" />
                  </span>
                </label>
              );
            } else {
              return <input {...props} />;
            }
          }}
          inputProps={{
            onKeyUp: this.onKeyUp,
            placeholder: placeholder,
            className: styles.input + " search-result-input",
          }}
          wrapperProps={{
            className: styles.wrapper,
          }}
          items={this.getOptions()}
          renderItem={this.renderItems}
          onChange={this.onChange}
          onSelect={this.onSelect}
          open={isOpen}
          renderMenu={(items) => (
            <div className={styles.menu}>
              <div className={styles["menu-items-wrap"]}>{items}</div>
              {this.renderShowAllBlock()}
            </div>
          )}
        />
      </div>
    );
  }

  renderDatepicker(searchType, calendarIcon, datePlaceholder) {
    if (searchType === "home-v2-bottom") {
      return;
    } else {
      return (
        <div className={styles.divider}>
          <SVGIcon name={calendarIcon} width="24px" fill="#333333"></SVGIcon>
          <input type="text" placeholder={datePlaceholder} className={styles["search-datepicker"]} readOnly={true} />
        </div>
      );
    }
  }

  renderHome() {
    const { placeholder, searchType } = this.props;
    const { isFocused, isOpen, isClearButtonVisible } = this.state;
    const wrapperClasses = `${styles["search-wrap-home"]} ${styles[searchType + "-search"] || ""} ${
      isFocused ? styles["is-focused"] : ""
    }`;
    const datePlaceholder = searchType === "home-v2" ? "Date" : "mm/dd/yyyy";
    const calendarIcon = searchType === "home-v2" ? "calendar-v2" : "calendar";
    const searchIcon = searchType === "home-v2" || "home-v2-bottom" ? "glass-v2" : "city";

    this.setIconSize(searchType);
    return (
      <div className={wrapperClasses} ref={this.setWrapperRef}>
        <div className={`${styles.divider} ${styles["divider-search"]}`}>
          {this.state.isFetchingResults ? (
            <Spinner></Spinner>
          ) : (
            <SVGIcon name={searchIcon} width="24px" fill="#333333"></SVGIcon>
          )}
          <Autocomplete
            ref={(component) => {
              this._textInput = component;
            }}
            getItemValue={(item) => item.name}
            value={this.state.value}
            renderInput={(props) => {
              return <input {...props} />;
            }}
            inputProps={{
              onKeyUp: this.onKeyUp,
              placeholder: placeholder,
              className: styles["input-home"] + " search-result-input",
            }}
            wrapperProps={{
              className: styles["wrapper-home"],
            }}
            items={this.getOptions()}
            renderItem={this.renderItems}
            onChange={this.onChange}
            onSelect={this.onMenuSelection}
            open={isOpen}
            renderMenu={(items) => (
              <div className={styles["menu-home"]}>
                <div className={styles["menu-items-wrap"]}>{items}</div>
                {this.renderShowAllBlock()}
              </div>
            )}
          />
        </div>

        {this.renderDatepicker(searchType, calendarIcon, datePlaceholder)}

        <button
          className={styles["search-btn"]}
          type="button"
          onClick={this.onSearch}
          disabled={this.state.isFetchingResults}
        >
          Search
        </button>
      </div>
    );
  }

  render() {
    // AB Testing home v2 Styles
    if (
      this.props.searchType === "home" ||
      this.props.searchType === "home-v2" ||
      this.props.searchType === "home-v2-bottom"
    ) {
      return this.renderHome();
    }
    return this.renderOriginal();
  }
}

AutoCompleteSearch.propTypes = {
  onSelect: func.isRequired,
  placeholder: string,
  urlShowAll: string,
  textShowAll: string,
  iconType: string,
  data: arrayOf(
    shape({
      id: any.isRequired,
      name: string.isRequired,
      type: string,
    })
  ).isRequired,
};

AutoCompleteSearch.defaultProps = {
  placeholder: " ",
  textShowAll: "Show all",
};

export default AutoCompleteSearch;
