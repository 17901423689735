import React from "react";
import styles from "./style.scss";
export default function Spinner() {
  return (
    <div className={styles["lds-ring"]}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}
