import React from 'react';
import PropTypes from 'prop-types';
import { getPath, getViewBox } from './icons';

const { string, object } = PropTypes;

function SVGIcon ({
  name = '',
  style = {},
  fill = '#000',
  width = '100%',
  className = '',
  height = '100%',
  viewBox = '0 0 100 100',
}) {
  return (
    <svg
      width={width}
      style={style}
      height={height}
      viewBox={getViewBox(name) || viewBox}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      {getPath(name, { fill })}
    </svg>
  );
}

SVGIcon.propTypes = {
  name: string.isRequired,
  style: object,
  fill: string,
  width: string,
  className: string,
  height: string,
  viewBox: string
};

export default SVGIcon;
