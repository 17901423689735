import axios from "axios";

export default class Service {
  static async getPlacesByName(name) {
    try {
      const apiString = `/api/typeahead?q=is:place%20name:"${encodeURIComponent(name)}"&limit=20`;
      const res = await axios.get(apiString);
      return res.status === 200 && res.data ? res.data : [];
    } catch (err) {
      console.error(err);
    }
  }

  static async algoliaTrackAutocomplete(params) {
    try {
      const apiString = "/api/insights/algolia";
      await axios.post(apiString, params);
    } catch (err) {
      console.error(err);
    }
  }
}
